import React from 'react';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    NormalQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const JugendZitate = () => (
    <InnerPage title="Zitate – Jugend" description="">
        <IntroHolder
            title="Zitate"
            subtitle="Jugend"
            breadcrumbs={{
                grandparent: 'Gemeinsames Handeln',
                parent: 'Jugend',
                'current-item': 'Zitate',
            }}
        />
        <TwoColumns>
            <div id="content">
                <NormalQuote reference="‘Abdu’l-Bahá, Gebete, Nr. 172">
                    O Herr! Laß diesen jungen Menschen strahlen, erweise diesem
                    armen Geschöpf Deine Freigebigkeit. Schenke ihm Wissen,
                    gewähre ihm jeden Morgen neue Kraft und behüte ihn unter dem
                    Obdach Deines Schutzes, dass er frei von Irrtum werde, sich
                    dem Dienste Deiner Sache hingebe, die Widerspenstigen leite,
                    die Unglücklichen führe, die Gefangenen befreie und die
                    Achtlosen erwecke, so dass alle durch Dein Gedenken und
                    Deinen Lobpreis gesegnet sind. Du bist der Mächtige, der
                    Kraftvolle.
                </NormalQuote>

                <NormalQuote reference="Universales Haus der Gerechtigkeit, 1. Juli 2013">
                    Obwohl eure Lebenswirklichkeiten von den unterschiedlichsten
                    Umständen geprägt sind, ist doch der Wunsch, eine
                    konstruktive Veränderung zu bewirken und die Fähigkeit zu
                    sinnvollem Dienst zu entwickeln – beides charakteristisch
                    für Eure Lebensphase –, weder durch Rasse oder Nationalität
                    begrenzt noch von materiellen Mitteln abhängig. Diese
                    strahlende Zeit der Jugend ist Euch allen gemein-sam und
                    wird von allen durchlebt, aber sie ist kurz und hin- und
                    hergerissen von zahlreichen gesellschaftlichen Kräften.
                </NormalQuote>

                <NormalQuote reference="Universales Haus der Gerechtigkeit, 8. Februar 2013">
                    Jede Generation junger Gläubiger erhält eine Gelegenheit,
                    für die Geschicke der Menschheit einen Beitrag zu leisten,
                    die einzigartig für ihre Lebenspanne ist. Für die
                    gegenwärtige Generation ist der Augenblick gekommen, tief
                    nachzudenken, sich zu verpflichten, sich zu stählen für ein
                    Leben des Dienstes, aus dem Segen im Überfluss strömen wird.
                </NormalQuote>

                <NormalQuote reference="Universales Haus der Gerechtigkeit, 10. Juni 1966">
                    Für jeden Menschen…sind es die Jugendjahre, in denen er
                    viele Entscheidungen trifft, die den Lauf seines Lebens
                    bestimmen. IN diesen Jahren wird er wahrscheinlich seinen
                    Beruf wählen, seine Ausbildung vervollständigen, seinen
                    Lebensunterhalt zu verdienen beginnen. Er wird heiraten und
                    eine eigene Familie gründen. Das Wichtigste von allem in
                    diesem Zeitraum ist, dass das Herz auf ernster Suche ist und
                    dass die geistigen Werte erlangt werden, die des Menschen
                    zukünftiges Verhalten bestimmen.
                </NormalQuote>
            </div>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default JugendZitate;
